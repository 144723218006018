import { Event } from './Event.js';

class MouseEvent extends Event {
	constructor(type, params) {
		super(type, params);
	}
}

/**
 * Static Propeties
 */
MouseEvent.CLICK = "click";
MouseEvent.DOUBLE_CLICK = "dblclick";
MouseEvent.MOUSE_DOWN = "mousedown";
MouseEvent.MOUSE_MOVE = "mousemove";
MouseEvent.MOUSE_OVER = "mouseover";
MouseEvent.MOUSE_OUT = "mouseout";
MouseEvent.MOUSE_UP = "mouseup";
MouseEvent.MOUSE_WHEEL = "mousewheel";
MouseEvent.TOUCH_START = "touchstart";
MouseEvent.TOUCH_MOVE = "touchmove";
MouseEvent.TOUCH_END = "touchend";
MouseEvent.TOUCH_CANCEL = "touchcancel";
MouseEvent.GESTURE_START = "gesturestart";
MouseEvent.GESTURE_CHANGE = "gesturechange";
MouseEvent.GESTURE_END = "gestureend";

export { MouseEvent };
