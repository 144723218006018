import { EventDispatcher } from '../events/EventDispatcher.js';
import { Event } from '../events/Event.js';
import { Window } from '../core/Window.js';

/**
 * Private Propeties
 */
var _enforcer = {};
var _instance;
var playPromise = null;

class WebCamera extends EventDispatcher {

	constructor(enf)
	{
		if(enf != _enforcer)
		{
			throw new Error("WebCamera is a Singleton Class.");
		}
		else
		{
			super();
			/**
			 * Propeties
			 */
			this.status = WebCamera.STATUS_NO_REQUEST;
			this.media = null;
			this.video = null;
			this.videoSettings = null;
		}
	}


	/**
	 * requestPermission
	 * @param cameraSide WebCamera.SIDE_FRONT or WebCamera.SIDE_BACK
	 */
	async requestPermission(cameraSide)
	{
		this.video = document.createElement("video");
		this.video.muted = this.video.playsinline = this.video.playsInline = this.video.autoplay = true;

		if (navigator && navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
			this.media = navigator.mediaDevices.getUserMedia({
				video: { facingMode: cameraSide, width: {exact: 640}, height: {exact: 480} },
				audio: false,
			});
			this.media
				.then(function(stream) {
					var settings = stream.getVideoTracks()[0].getSettings();
					_instance.videoSettings = settings;

					var handler;
					_instance.video.addEventListener('loadeddata', handler = () => {
						_instance.video.removeEventListener('loadeddata', handler);
						_instance.video.width = _instance.video.videoWidth;
						_instance.video.height = _instance.video.videoHeight;
						_instance.videoSettings.width = _instance.video.videoWidth;
						_instance.videoSettings.height = _instance.video.videoHeight;
						_instance.dispatchEvent(new Event(WebCamera.EVENT_PERMISSION_RESULT, _instance.status));
					});

					_instance.video.srcObject = stream;
					playPromise = _instance.video.play();

					_instance.status = WebCamera.STATUS_GRANTED;
					/**
					const win = Window.getInstance();
					const check = () => {
						if (_instance.video.videoWidth > 0 && loadeddata) {
							win.removeEventListener(Event.REQUEST_ANIMATION_FRAME, check);
							_instance.video.width = _instance.video.videoWidth;
							_instance.video.height = _instance.video.videoHeight;
							_instance.videoSettings.width = _instance.video.videoWidth;
							_instance.videoSettings.height = _instance.video.videoHeight;
							_instance.dispatchEvent(new Event(WebCamera.EVENT_PERMISSION_RESULT, _instance.status));
						}
					};
					win.addEventListener(Event.REQUEST_ANIMATION_FRAME, check);
					/**/
				})
				.catch(function(err) {
					if (err) {
						_instance.status = WebCamera.STATUS_IGNORED;
						_instance.dispatchEvent(new Event(WebCamera.EVENT_PERMISSION_RESULT, _instance.status));
					}
				});
		} else {
			_instance.status = _.WebCamera.STATUS_IGNORED;
			_instance.dispatchEvent(new _.Event(_.WebCamera.EVENT_PERMISSION_RESULT, _instance.status));
		}
	}

	/**
	 * stop
	 */
	stop()
	{
		if (playPromise != null) {
			var _self = this;
			playPromise.then(() => {
				// Automatic playback started!
				// Show playing UI.
				// We can now safely pause video...
				if (_self.video && _self.video.srcObject) {
					var stream = _self.video.srcObject;
					stream.getTracks().forEach(function(track) {
						track.stop();
					});
					_self.video.srcObject = null;
					_self.video = null;
				}
			})
			.catch(error => {
				// Auto-play was prevented
				// Show paused UI.
			});
			playPromise = null;
		}
	};
};



/**
 * Static Propeties
 */
WebCamera.STATUS_NO_REQUEST = "norequest";
WebCamera.STATUS_GRANTED = "granted";
WebCamera.STATUS_IGNORED = "ignored";
WebCamera.SIDE_FRONT = "user";
WebCamera.SIDE_BACK = "environment";
WebCamera.EVENT_PERMISSION_RESULT = "event_permission_result";

/**
 * getInstance
 * @return	_instance
 */
WebCamera.getInstance = function()
{
	if(!_instance) _instance = new WebCamera(_enforcer);
	return _instance;
};

export { WebCamera };
