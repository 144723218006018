import { EventDispatcher } from '../events/EventDispatcher.js';
import { Event } from '../events/Event.js';
import { MouseEvent } from '../events/MouseEvent.js';

/**
 * Private Propeties
 */
var _enforcer = {};
var _instance;
var _animationID;
var _animationFunctions = [];


/**
 * listenGlobalEvent
 * @param	type
 * @param	listener
 */
var listenGlobalEvent = function(type, listener)
{
	if(type == MouseEvent.MOUSE_WHEEL)
	{
		if(window.addEventListener){
			window.addEventListener("DOMMouseScroll", listener, false);
		}
	}
	if(typeof(window.addEventListener) != "undefined")
	{
		window.addEventListener(type, listener, false);
	}
	else if(typeof(window.attachEvent) != "undefined")
	{
		window.attachEvent("on" + type, listener);
	}
	else
	{
		if(window["on" + type] != null)
		{
			var existenceListener = window["on" + type];
			window["on" + type] = function(event)
			{
				existenceListener(event);
				listener(event);
			};
		}
		else
		{
			window["on" + type] = listener;
		}
	}
};

/**
 * onLoadHandler
 * @param	event
 */
var onLoadHandler = function(event)
{
	_instance.dispatchEvent(new Event(Event.LOAD));
};

/**
 * onResizeHandler
 * @param	event
 */
var onResizeHandler = function(event)
{
	_instance.dispatchEvent(new Event(Event.RESIZE));
};

/**
 * onUnLoadHandler
 * @param	event
 */
var onUnLoadHandler = function(event)
{
	_instance.dispatchEvent(new Event(Event.UNLOAD));
};

/**
 * onMouseWheelHandler
 * @param	event
 */
var onMouseWheelHandler = function(event)
{
	var delta = event.detail != 0 ? event.detail : event.wheelDelta;
	_instance.dispatchEvent(new Event(MouseEvent.MOUSE_WHEEL, delta));
};

var animationFrame = function()
{
	_instance.dispatchEvent(new Event(Event.REQUEST_ANIMATION_FRAME));
	_animationID = window.requestAnimationFrame(animationFrame);
};

class Window extends EventDispatcher {

	constructor(enf)
	{
		if(enf != _enforcer)
		{
			throw new Error("Window is a Singleton Class.");
		}
		else
		{
			super();
			var globalEvents = [Event.LOAD, Event.RESIZE, Event.UNLOAD, MouseEvent.MOUSE_WHEEL];
			var globalListeners = [onLoadHandler, onResizeHandler, onUnLoadHandler, onMouseWheelHandler];
			var length = globalEvents.length;
			for(var i = 0; i < length; ++i) listenGlobalEvent(globalEvents[i], globalListeners[i]);

			window.requestAnimationFrame = (function() {
				return window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				function(f) { return window.setTimeout(f, 1000 / 60); };
			}());
			if (!window.cancelAnimationFrame) window.cancelAnimationFrame = function(id) { clearTimeout(id); };
		}
	}

	/**
	 * getWindowWidth
	 * @return	windowWidth
	 */
	getWindowWidth()
	{
		if (window.innerWidth) return window.innerWidth;
		if (document.documentElement && document.documentElement.clientWidth) return document.documentElement.clientWidth;
		else if (document.body && document.body.clientWidth) return document.body.clientWidth;
		return null;
	}

	/**
	 * getWindowHeight
	 * @return	windowHeight
	 */
	getWindowHeight()
	{
		if (window.innerHeight) return window.innerHeight;
		if (document.documentElement && document.documentElement.clientHeight) return document.documentElement.clientHeight;
		else if (document.body && document.body.clientHeight) return document.body.clientHeight;
		return null;
	}

	/**
	 * getPageWidth
	 * @return	pageWidth
	 */
	getPageWidth()
	{
		var xScroll, pageWidth;
		if(window.innerWidth && window.scrollMaxX) xScroll = document.body.scrollWidth;
		else if (document.body.scrollWidth > document.body.offsetWidth) xScroll = document.body.scrollWidth;
		else xScroll = document.body.offsetWidth;
		var windowWidth = this.getWindowWidth();
		if(xScroll < windowWidth) pageWidth = windowWidth;
		else pageWidth = xScroll;
		return pageWidth;
	}

	/**
	 * getPageHeight
	 * @return	pageHeight
	 */
	getPageHeight()
	{
		var yScroll, pageHeight;
		if(window.innerHeight && window.scrollMaxY) yScroll = document.body.scrollHeight;
		else if (document.body.scrollHeight > document.body.offsetHeight) yScroll = document.body.scrollHeight;
		else yScroll = document.body.offsetHeight;
		var windowHeight = this.getWindowHeight();
		if(yScroll < windowHeight) pageHeight = windowHeight;
		else pageHeight = yScroll;
		return pageHeight;
	}

	/**
	 * getScrollX
	 * @return	scrollX
	 */
	getScrollX()
	{
		if(self.pageXOffset) return self.pageXOffset;
		else if(document.documentElement && document.documentElement.scrollLeft) return document.documentElement.scrollLeft;
		else if(document.body) return document.body.scrollLeft;
		return 0;
	}

	/**
	 * getScrollY
	 * @return	scrollY
	 */
	getScrollY()
	{
		if(self.pageYOffset) return self.pageYOffset;
		else if(document.documentElement && document.documentElement.scrollTop) return document.documentElement.scrollTop;
		else if(document.body) return document.body.scrollTop;
		return 0;
	}

	/**
	 * [override] addEventListener
	 * @param	type
	 * @param	listener
	 */
	addEventListener(type, listener)
	{
		this.__proto__.__proto__.addEventListener.apply(this, arguments);

		if(type == Event.REQUEST_ANIMATION_FRAME)
		{
			var length = _animationFunctions.length;
			for(var i = 0; i < length; ++i)
			{
				if(_animationFunctions[i] == listener) return;
			}

			_animationFunctions.push(listener);
			if(_animationFunctions.length == 1) animationFrame();
		}
	}

	/**
	 * [override] removeEventListener
	 * @param	type
	 * @param	listener
	 */
	removeEventListener(type, listener)
	{
		//ns._super.removeEventListener.apply(this, arguments);
		this.__proto__.__proto__.removeEventListener.apply(this, arguments);

		if(type == Event.REQUEST_ANIMATION_FRAME)
		{
			var length = _animationFunctions.length;
			for(var i = 0; i < length; ++i)
			{
				if (_animationFunctions[i] == listener) break;
			}
			if (i != length) _animationFunctions.splice(i, 1);
			if (_animationFunctions.length == 0) cancelAnimationFrame(_animationID);
		}
	}
};

/**
 * getInstance
 * @return	_instance
 */
Window.getInstance = function()
{
	if(!_instance) _instance = new Window(_enforcer);
	return _instance;
};

export { Window };

