import { Event } from './events/Event.js';
import { EventDispatcher } from './events/EventDispatcher.js';
import { WebCamera } from './utils/WebCamera.js';
import { gsap } from '../libs/gsap/index.js';

document.addEventListener('DOMContentLoaded', async () => {

	const modelURL = "assets/savedmodel/ssdlite_mobilenet_v2/model.json";
	const windowWidth = window.innerWidth;
	const windowHeight = window.innerHeight;
	const dispatcher = new EventDispatcher();
	const loader = document.getElementById("loader");
	const texture = document.createElement("canvas");
	const btnArea = document.createElement("div");
	const ctx = texture.getContext("2d");
	let loaderHeight = loader.clientHeight;
	let yolo;
	let video;
	let preObjects = [];
	let objects = [];
	let preBtns = [];
	let preBtnAreas = [];
	let canvasRatio;

	const vwToPx = (vw) => {
		return vw * window.innerWidth * 0.01;
	};
	const easeExpo = (v) => {
		return v === 1 ? 1 : 1 - Math.pow(2, -10 * v);
	};
	const easeQuart = (v) => {
		return 1 - --v * v * v * v;
	};

	//loading animation
	gsap.fromTo(loader, {opacity:0, top:"53%"}, {opacity:1, top:"50%", duration:0.5, onComplete:async () => {

		yolo = await ml5.objectDetector(modelURL, async () => {
			loader.style.height = loaderHeight + "px";
			loader.style.overflow = "hidden";

			const child_arr = loader.children;
			gsap.to(child_arr[0], {opacity:0, marginTop:"-5vh", duration:0.5 });
			gsap.to(child_arr[1], {opacity:0, duration:0.5 });

			gsap.to(loader, {height:loaderHeight * 0.5, backgroundColor:'rgba(255, 255, 255, 1)', duration:0.5, onComplete:() => {
				loader.removeChild(child_arr[0]);
				loader.removeChild(child_arr[0]);
				const p = document.createElement("p");
				p.style.fontSize = "2.5vw";
				p.style.fontWeight = "bold";
				p.style.color = "black";
				p.innerText = "Activate Your Camera";
				loader.appendChild(p);


				let clickHandler;
				loader.addEventListener("click", clickHandler = async () => {
					loader.removeEventListener("click", clickHandler);
					loader.style.cursor = "auto";
					loader.style.display = "none";
					dispatcher.dispatchEvent(new Event("webcam_request"));
				});
			} });
		});
	}});

	//webcam_request
	dispatcher.addEventListener("webcam_request", async () => {

		//Camera取得許可結果
		WebCamera.getInstance().addEventListener(WebCamera.EVENT_PERMISSION_RESULT, async (event) => {

			if (event.params == WebCamera.STATUS_GRANTED) {

				//videoElement
				video = WebCamera.getInstance().video;
				texture.width = video.width * 2;
				texture.height = video.height * 2;
				texture.style.position = "absolute";
				texture.style.zIndex = 0;
				texture.style.height = windowHeight + "px";
				let videoWidth = (windowHeight * video.width / video.height);
				texture.style.width = videoWidth + "px";
				texture.style.top = 0;
				texture.style.left = (windowWidth - videoWidth) * 0.5 + "px";
				document.body.appendChild(texture);
				canvasRatio = videoWidth / texture.width;

				btnArea.width = texture.width;
				btnArea.height = texture.height;
				btnArea.style.position = "absolute";
				btnArea.style.width = texture.style.width;
				btnArea.style.height = texture.style.height;
				btnArea.style.top = texture.style.top;
				btnArea.style.left = texture.style.left;
				btnArea.style.zIndex = 1;
				document.body.appendChild(btnArea);

				dispatcher.dispatchEvent(new Event("start_detecting"));

			}
		});

		//カメラ取得許可申請
		await WebCamera.getInstance().requestPermission(WebCamera.SIDE_BACK);
	});

	//start_detecting
	dispatcher.addEventListener("start_detecting", async () => {
		const createRoundRectPath = (ctx, x, y, w, h, r) => {
			ctx.moveTo(x + r, y);
			ctx.lineTo(x + w - r, y);
			ctx.arc(x + w - r, y + r, r, Math.PI * (3/2), 0, false);
			ctx.lineTo(x + w, y + h - r);
			ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * (1/2), false);
			ctx.lineTo(x + r, y + h);       
			ctx.arc(x + r, y + h - r, r, Math.PI * (1/2), Math.PI, false);
			ctx.lineTo(x, y + r);
			ctx.arc(x + r, y + r, r, Math.PI, Math.PI * (3/2), false);
		}
		const draw = () => {
			// Clear part of the canvas
			ctx.fillStyle = "#000000"
			ctx.fillRect(0, 0, texture.width, texture.height);
			ctx.font = "20px Arial";

			// draw video
			ctx.drawImage(video, 0, 0, video.width, video.height, 0, 0, texture.width, texture.height);

			const needDraw = [];
			const needBtns = [];
			const needBtnAreas = [];

			// 差分
			const thredshold_distance = 200;
			const thredshold_area = 300000;

			for (let i = 0; i < objects.length; i++) {
				const cur = objects[i];
				const curLabel = cur.label;
				const curCenter = { x:cur.x + cur.width * 0.5, y:cur.y + cur.height * 0.5 };
				const curArea = cur.width * cur.height;

				let newObjFlg = true;

				for (let j = 0; j < preObjects.length; j++) {
					if (!newObjFlg) break;

					const pre = preObjects[j];
					const preLabel = pre.label;
					const preCenter = { x:pre.x + pre.width * 0.5, y:pre.y + pre.height * 0.5 };
					const preArea= pre.width * pre.height;
					
					if (curLabel == preLabel) {
						const disx = curCenter.x - preCenter.x;
						const disy = curCenter.y - preCenter.y;
						const distance = Math.sqrt(disx * disx + disy * disy);
						if (distance < thredshold_distance && Math.abs(curArea - preArea) < thredshold_area) {
							pre.targetX = cur.x;
							pre.targetY = cur.y;
							pre.targetWidth = cur.width;
							pre.targetHeight = cur.height;
							needDraw.push(pre);
							newObjFlg = false;

							needBtns.push(preBtns[j]);
							needBtnAreas.push(preBtnAreas[j]);
						}
					}
				}

				if (newObjFlg) {
					cur.targetX = cur.x;
					cur.targetY = cur.y;
					cur.targetWidth = cur.width;
					cur.targetHeight = cur.height;
					cur.x = curCenter.x;
					cur.y = curCenter.y;
					cur.width = 0;
					cur.height = 0;
					cur.opacity = 0;
					needDraw.push(cur);

					//new btn
					const btn = document.createElement("div");
					btn.className = "btn";
					//btn.style.opacity = 0;
					btn.style.width = 0 + "vw";
					btn.innerText = cur.label;
					btnArea.appendChild(btn);
					needBtns.push(btn);

					//new btnarea
					const area = document.createElement("div");
					area.className = "btn_area";
					area.style.opacity = 0;
					btnArea.appendChild(area);
					needBtnAreas.push(area);
				}
			}

			//不要なbtn削除
			const removeBtns = [];
			for (let i = 0; i < btnArea.children.length; i++) {
				let removeFlg = true;
				for (let j = 0; j < needBtns.length; j++) {
					if (btnArea.children[i] === needBtns[j]) {
						removeFlg = false;
						break;
					}
				}
				if (removeFlg) {
					for (let j = 0; j < needBtnAreas.length; j++) {
						if (btnArea.children[i] === needBtnAreas[j]) {
							removeFlg = false;
							break;
						}
					}
				}
				if (removeFlg) removeBtns.push(btnArea.children[i]);
			}
			for (let i = 0; i < removeBtns.length; i++) {
				btnArea.removeChild(removeBtns[i]);
			}


			const easeValue = 0.2;
			const labelMargin = vwToPx(9);
			for (let i = 0; i < needDraw.length; i++) {
				const obj = needDraw[i];
				obj.x += (obj.targetX - obj.x) * easeValue;
				obj.y += (obj.targetY - obj.y) * easeValue;
				obj.width += (obj.targetWidth - obj.width) * easeValue;
				obj.height += (obj.targetHeight - obj.height) * easeValue;
				obj.opacity += 0.08;
				if (obj.opacity > 1) obj.opacity = 1;
				/**
				ctx.beginPath();
				createRoundRectPath(ctx, obj.x * 2, obj.y * 2, obj.width * 2, obj.height * 2, 20);
				//ctx.rect(objects[i].x * 2, objects[i].y * 2, objects[i].width * 2, objects[i].height * 2);
				ctx.lineWidth = 3;
				ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
				ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
				ctx.fill();
				ctx.stroke();
				ctx.fillStyle = 'rgb(255, 127, 127)';
				ctx.fillText(obj.label, obj.x * 2, obj.y * 2 > 20 ? obj.y * 2 - 10 : 20);
				/**/

				needBtns[i].style.left = obj.x * 2 * canvasRatio + "px";
				needBtns[i].style.top = (obj.y * 2 * canvasRatio - labelMargin > 10 ? obj.y * 2 * canvasRatio - labelMargin - 5 : 10) + "px";
				//needBtns[i].style.opacity = obj.opacity;
				needBtns[i].style.width = (easeQuart(obj.opacity) * 30) + "vw";

				needBtnAreas[i].style.left = obj.x * 2 * canvasRatio + "px";
				needBtnAreas[i].style.top = obj.y * 2 * canvasRatio + "px";
				needBtnAreas[i].style.width = obj.width * 2 * canvasRatio + "px";
				needBtnAreas[i].style.height = obj.height * 2 * canvasRatio + "px";
				needBtnAreas[i].style.opacity = obj.opacity;
			}
			preObjects = needDraw;
			preBtns = needBtns;
			preBtnAreas = needBtnAreas;
		}
		const detect = () => {
			yolo.detect(video, function(err, results) {
				if (err) {
					console.log(err);
					return
				}
				objects = results;

				if (objects) {
					draw();
				}

				detect();
			});
		};
		detect();
	});
});
