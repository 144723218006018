class Event {
	constructor(type, params) {

		/**
		 * Propeties
		 */
		this.type = type;
		this.params = params;
	}
}

/**
 * Static Propeties
 */
Event.COMPLETE = "complete";
Event.LOAD = "load";
Event.REQUEST_ANIMATION_FRAME = "requestAnimationFrame";
Event.RESIZE = "resize";
Event.UNLOAD = "unload";
Event.CAN_PLAY = "canplay";
Event.LOADED_DATA = "loadeddata";
Event.ENDED = "ended";
Event.SEEKED = "seeked";

export { Event };
