class EventDispatcher {

	constructor() {

	}

	/**
	 * addEventListener
	 * @param	type
	 * @param	listener
	 * @param	useCapture
	 */
	addEventListener(type, listener, useCapture)
	{
		if(typeof(type) != "string") return;
		if(typeof(listener) != "function") return;
		if( this._listeners === undefined ) this._listeners = {};
		if(!this._listeners[type]) this._listeners[type] = [];
		var listeners = this._listeners[type];
		var length = listeners.length;
		for(var i = 0; i < length; ++i) if(listeners[i] == listener) return;
		this._listeners[type][length] = listener;
	}

	/**
	 * removeEventListener
	 * @param	type
	 * @param	listener
	 */
	removeEventListener(type, listener)
	{
		if(typeof(type) != "string") return;
		if(typeof(listener) != "function") return;
		if(this._listeners === undefined) return;
		if(!this._listeners[type]) return;
		var listeners = this._listeners[type];
		var length = listeners.length;
		for(var i = 0; i < length; ++i) if(listeners[i] == listener) break;
		if(i != length) this._listeners[type].splice(i, 1);
	}

	/**
	 * removeAllEventListener
	 */
	removeAllEventListener()
	{
		if(this._listeners === undefined) return;
		for(var s in this._listeners)
		{
			this._listeners[s].length = 0;
			delete this._listeners[s];
		}
		this._listeners = {};
	}

	/**
	 * hasEventListener
	 * @param	type
	 * @param	listener
	 * @return	boolean
	 */
	hasEventListener(type, listener)
	{
		if(typeof(type) != "string") return false;
		if(typeof(listener) != "function") return false;
		if(this._listeners === undefined) return false;
		if(!this._listeners[type]) return false;
		var listeners = this._listeners[type];
		var length = listeners.length;
		for(var i = 0; i < length; ++i) if(listeners[i] == listener) return true;
		return false;
	}

	/**
	 * dispatchEvent
	 * @param	event
	 */
	dispatchEvent(event)
	{
		if(!event) return;
		if(!event["type"]) return;
		if(this._listeners === undefined) return;
		var type = event["type"];
		if(!this._listeners[type]) return;
		event.target = this;
		var listeners = this._listeners[type];
		var length = listeners.length;
		for(var i = 0; i < length; ++i)
		{
			if (listeners[i]) listeners[i](event);
		}
	}
}

export { EventDispatcher };